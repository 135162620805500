import { _wlog } from '../utils/Logging';

const getCircularReplacer = () => {
  const seen = new WeakSet();
  return (key: string, value: any) => {
    if (typeof value === 'object' && value !== null) {
      if (seen.has(value)) {
        return;
      }
      seen.add(value);
    }
    return value;
  };
};

const logDefaultCaseWarningReducer = (
  location: string,
  type: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  payload: any
) =>
  _wlog(
    `Executed action in reducer ('${location}') with an unknown type ('${type}') with payload ('${JSON.stringify(
      payload,
      getCircularReplacer()
    )}')`
  );

export default logDefaultCaseWarningReducer;
